<template>
  <div style="padding: 1rem 0">
    <div
      style="
        padding: 0 1rem 1rem 1rem;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      "
    >
      <img
        style="width: 10px; height: auto"
        src="../assets/common-01.png"
        @click="goBack"
      />
      <span style="margin-left: 1rem; font-size: 1rem; font-weight: bold">{{
        $t("order.item_01")
      }}</span>
    </div>

    <div
      style="
        padding: 1rem;
        background-color: #f9fbfc;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div>
        <p style="margin: 0; font-size: 1rem; font-weight: bold">
          {{ showDate() }}
        </p>
        <p style="margin: 0; color: #989898">
          <span>{{ $t("order.item_02") }}</span>
          <span v-for="(i, num) in infos" :key="num">
            <span style="margin: 0 5px">{{
              switchCurrency(i.pay_currency)
            }}</span>
            <span> {{ parseFloat(i.total) }}</span>
          </span>
        </p>
      </div>

      <q-btn icon="event" round color="primary">
        <q-popup-proxy
          @before-show="updateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            v-model="proxyDate"
            minimal
            range
            style="position: absolute; bottom: 0; width: 100%; border-radius: 0"
          >
            <div class="row">
              <div class="col-6" style="text-align: center">
                <q-btn
                  color="grey-7"
                  flat
                  no-caps
                  v-close-popup
                  :label="$t('common.item_04')"
                />
              </div>
              <div class="col-6" style="text-align: center">
                <q-btn
                  style="
                    color: white;
                    background-color: #3086fc;
                    padding: 0 2.3rem;
                    border-radius: 20px;
                  "
                  flat
                  no-caps
                  v-close-popup
                  :label="$t('common.item_03')"
                  @click="submit"
                />
              </div>
            </div>
          </q-date>
        </q-popup-proxy>
      </q-btn>
    </div>

    <div class="row">
      <div
        class="col-12"
        style="
          padding: 1rem;
          text-align: center;
          font-size: 1rem;
          color: #989898;
        "
        v-if="!httpProcess && (!list || list.length < 1)"
      >
        {{ $t("common.item_06") }}
      </div>
      <div
        class="col-12"
        style="padding: 0.5rem 1rem 1rem 1rem"
        v-for="(item, index) in list"
        :key="index"
        v-else
      >
        <div
          class="row"
          style="display: flex; flex-flow: row wrap; align-items: center"
        >
          <div class="col-2">
            <q-avatar
              size="45px"
              font-size="35px"
              color="teal"
              text-color="white"
              icon="ion-logo-rss"
            />
          </div>
          <div
            class="col-10"
            style="padding-left: 1rem; border-bottom: 1px solid #e9e9e9"
          >
            <div
              class="row"
              style="display: flex; flex-flow: row wrap; align-items: center"
            >
              <div class="col-8" style="padding-bottom: 0.5rem">
                <p style="margin: 0; font-size: 1.1rem; font-weight: bold">
                  <span>{{ item.data }}</span>
                  <span style="margin: 0 5px">{{ item.active }}</span>
                  <span> {{ $t("common.item_25") }} </span>
                </p>
                <p style="margin: 0; color: #989898">{{ item.create_at }}</p>
              </div>
              <div
                class="col-4"
                style="
                  text-align: right;
                  font-size: 1.1rem;
                  font-weight: bold;
                  color: #2b70fa;
                "
              >
                <span style="margin-right: 5px">{{
                  switchCurrency(item.pay_currency)
                }}</span>
                <span>{{ parseFloat(item.pay_amount) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      httpProcess: false,

      proxyDate: null,
      date: null,

      infos: [],
      list: [],
    };
  },
  created() {
    this.date = this.getDate();
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getDate() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      if (m.toString().length == 1) m = "0" + m;
      if (d.toString().length == 1) d = "0" + d;
      return y + "/" + m + "/" + d;
    },
    showDate() {
      if (typeof this.date == "object") {
        return this.date.from + "~" + this.date.to;
      } else {
        return this.date;
      }
    },
    switchCurrency: function (v) {
      switch (v) {
        case "CNY":
          return "￥";
        case "HKD":
          return "HK$";
        case "KRW":
          return "₩";
        case "JPY":
          return "¥";
        case "EUR":
          return "€";
        case "RUB":
          return "₽";
        case "GBP":
          return "£";
        case "USD":
          return "$";
        default:
          return "";
      }
    },

    updateProxy() {
      this.proxyDate = this.date;
    },
    submit() {
      if (this.proxyDate) {
        this.date = this.proxyDate;
        this.getData(this.date);
      }
    },
    getData(date) {
      var arg = {};
      if (date) {
        if (typeof this.date == "object") {
          arg.start_date = date.from;
          arg.end_date = date.to;
        } else {
          arg.start_date = date;
        }
      }

      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;
      self
        .$axios({
          method: "post",
          url: "/api/query/buy/order",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: arg,
        })
        .then((res) => {
          self.httpProcess = false;
          if (res.data.list && res.data.list.length) {
            self.list = res.data.list;
          } else {
            self.list = [];
          }
          if (res.data.infos && res.data.infos.length) {
            self.infos = res.data.infos;
          } else {
            self.infos = [];
          }
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>
